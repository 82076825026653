import { AuthorRole } from '@/base/interfaces/Author';

const authorRoleTexts: Map<AuthorRole, string> = new Map([
  ['author', '저자'],
  ['story_writer', '글'],
  ['translator', '번역'],
  ['illustrator', '그림'],
  ['original_author', '원작'],
  ['author_photo', '사진'],
  ['planner', '기획'],
  ['bibliographical_introduction', '해제'],
  ['compiler', '엮음'],
  ['commentator', '해설'],
  ['editor', '편집'],
  ['supervise', '감수'],
  ['performer', '연주자'],
  ['original_illustrator', '원화'],
]);

const authorOrders = Array.from(authorRoleTexts.keys()).reduce<Partial<Record<AuthorRole, number>>>(
  (object, key, index) => ({ ...object, [key]: index }),
  {},
) as Record<AuthorRole, number>;

export const sortAuthors = <T extends { role: AuthorRole }>(authors: T[]): T[] =>
  [...authors].sort(
    (authorA, authorB) => (authorOrders[authorA.role] ?? Infinity) - (authorOrders[authorB.role] ?? Infinity),
  );
