const a = (t, u = 500, r = !1) => {
  let e = null, n, l = 0, s = 0;
  return (...i) => {
    if (n = i, e !== null) {
      l = Date.now(), s += 1;
      return;
    }
    r && Date.now() - l > u ? t(...i) : s += 1, l = Date.now(), e = setTimeout(() => {
      s > 0 && t(...n), s = 0, e = null;
    }, u);
  };
};
function o(t, u, r = !1) {
  let e = null;
  return (...n) => {
    const l = r && e === null;
    l && t(...n), e !== null && clearTimeout(e), e = setTimeout(() => {
      e = null, !l && t(...n);
    }, u);
  };
}
export {
  o as d,
  a as t
};
