import { useCallback, useState } from 'react';

import { useMount } from '@/hooks/useMount';
import { getStorageItemJSON, LOCAL_STORAGE_KEYS, setStorageItemJSON } from '@/utils/storage';

export interface SearchHistory {
  history: string[];
  addHistory: (history: string) => void;
  removeHistory: (history: string) => void;
  clearHistory: () => void;
  historyEnabled: boolean;
  setHistoryEnabled: (historyEnabled: boolean) => void;
}

export const useSearchHistory = (): SearchHistory => {
  const [history, setHistoryRaw] = useState<string[]>([]);
  const [historyEnabled, setHistoryEnabledRaw] = useState(false);

  const fetchHistoryEnabled = useCallback(() => {
    const latestHistoryEnabled = getStorageItemJSON('local', LOCAL_STORAGE_KEYS.INSTANT_SEARCH_HISTORY_ENABLED);
    if (typeof latestHistoryEnabled === 'boolean') {
      return latestHistoryEnabled;
    }

    return historyEnabled;
  }, [historyEnabled]);

  const fetchHistory = useCallback(() => {
    const latestHistory = getStorageItemJSON('local', LOCAL_STORAGE_KEYS.INSTANT_SEARCH_HISTORY);
    if (Array.isArray(latestHistory)) {
      return latestHistory;
    }

    return history;
  }, [history]);

  const setHistoryEnabled = useCallback((newEnabled: boolean) => {
    setHistoryEnabledRaw(newEnabled);
    setStorageItemJSON('local', LOCAL_STORAGE_KEYS.INSTANT_SEARCH_HISTORY_ENABLED, newEnabled);
  }, []);

  const setHistory = useCallback((newHistory: string[]) => {
    setHistoryRaw(newHistory);
    setStorageItemJSON('local', LOCAL_STORAGE_KEYS.INSTANT_SEARCH_HISTORY, newHistory);
  }, []);

  const addHistory = useCallback(
    (newItem: string) => {
      const latestHistoryEnabled = fetchHistoryEnabled();
      setHistoryEnabled(latestHistoryEnabled);

      if (!latestHistoryEnabled) {
        return;
      }

      const latestHistory = fetchHistory();
      const newHistory = Array.from(new Set<string>([newItem, ...latestHistory])).slice(0, 5);
      setHistory(newHistory);
    },
    [fetchHistory, fetchHistoryEnabled, setHistory, setHistoryEnabled],
  );

  const removeHistory = useCallback(
    (removedItem: string) => {
      const latestHistory = fetchHistory();
      const newHistory = latestHistory.filter(value => value !== removedItem);
      setHistory(newHistory);
    },
    [fetchHistory, setHistory],
  );

  const clearHistory = useCallback(() => setHistory([]), [setHistory]);

  useMount(() => {
    setHistoryRaw(fetchHistory());
    setHistoryEnabledRaw(fetchHistoryEnabled());
  });

  return {
    history,
    addHistory,
    removeHistory,
    clearHistory,
    historyEnabled,
    setHistoryEnabled,
  };
};
