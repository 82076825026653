/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef } from 'react';

type Params = {
  onScrollRightEnd: (isRightEnd: boolean) => void;
};

export const useOnScrollRightEnd = <T extends HTMLElement = HTMLElement>({ onScrollRightEnd }: Params) => {
  const scrollableRef = useRef<T>(null);

  const handleScroll = useCallback(() => {
    const element = scrollableRef.current;

    if (!element) {
      return;
    }

    if (element.scrollWidth - element.scrollLeft - element.clientWidth < 1) {
      onScrollRightEnd(true);
    } else {
      onScrollRightEnd(false);
    }
  }, []);

  useEffect(() => {
    const element = scrollableRef.current;

    if (element && element.scrollWidth > element.clientWidth) {
      onScrollRightEnd(false);
    } else {
      onScrollRightEnd(true);
    }

    element?.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      element?.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [handleScroll]);

  return scrollableRef;
};
