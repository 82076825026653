import {
  AdultOption,
  SearchGridParams,
  SearchOrder as RiGridSearchOrder,
  SearchWhat,
} from '@ridi-web/gql-client-codegen';
import {
  Boolean as RBoolean,
  Number as RNumber,
  Partial as RPartial,
  Record as RRecord,
  Runtype,
  Static as RStatic,
  String as RString,
} from 'runtypes';

import {
  RSearchNumberString,
  RSearchOrder,
  RSearchRequestOptions,
  RSearchYesNo,
  SearchOrder,
  SearchRequestOptions,
  SearchRequestQuery as SearchAPIRequestQuery,
  SearchYesNo,
} from '@/services/searchApi/search/interfaces/SearchRequest';

const RSearchNonNegativeInteger = RNumber.withConstraint(num => Number.isInteger(num) && num >= 0);
export const RSearchQuery = RRecord({
  keyword: RString,
}).And(
  RPartial({
    page: RSearchNonNegativeInteger,
    categoryId: RSearchNonNegativeInteger,
    order: RSearchOrder,
    isAdultExcluded: RBoolean,
    isSerial: RBoolean,
    isRental: RBoolean,
    isSelect: RBoolean,
  }),
);
export type SearchQuery = RStatic<typeof RSearchQuery>;

export const RSearchURLRequestQuery = RSearchRequestOptions.And(
  RPartial({
    q: RString,
    page: RSearchNumberString,
  }),
);
export type SearchURLRequestQuery = RStatic<typeof RSearchURLRequestQuery>;

const toAsIs = <T>(value: T): T => value;
const toString = (value: number): string => `${value}`;
const toNumber = (value: string): number => parseInt(value, 10);
const toYesNo = (value: boolean): 'y' | 'n' => (value ? 'y' : 'n');
const toBoolean = (value: 'y' | 'n') => value === 'y';

const mapOrUndefined = <T, M = T>(type: Runtype<T>, value: unknown, map: (value: T) => M): M | undefined => {
  const validated = type.validate(value);
  if (validated.success) {
    return map(validated.value);
  }

  return undefined;
};

const buildSearchRequestOptions = (searchQuery: SearchQuery): SearchRequestOptions => {
  const query: SearchRequestOptions = {};

  query.category_id = mapOrUndefined<number, string>(RSearchNonNegativeInteger, searchQuery.categoryId, toString);
  query.order = mapOrUndefined<SearchOrder>(RSearchOrder, searchQuery.order, toAsIs);
  query.adult_exclude = mapOrUndefined<boolean, SearchYesNo>(RBoolean, searchQuery.isAdultExcluded, toYesNo);
  query.serial = mapOrUndefined<boolean, SearchYesNo>(RBoolean, searchQuery.isSerial, toYesNo);
  query.rent = mapOrUndefined<boolean, SearchYesNo>(RBoolean, searchQuery.isRental, toYesNo);
  query.select = mapOrUndefined<boolean, SearchYesNo>(RBoolean, searchQuery.isSelect, toYesNo);

  return query;
};

export const encodeSearchURLRequestQuery = (searchQuery: SearchQuery): string => {
  const builtOptions = buildSearchRequestOptions(searchQuery);
  const builtQuery = {
    ...builtOptions,
    page: mapOrUndefined<number, string>(RSearchNonNegativeInteger, searchQuery.page, toString),
  };

  const params = new URLSearchParams();
  params.append('q', searchQuery.keyword);

  const keys = Object.keys(builtQuery) as (keyof typeof builtQuery)[];
  keys.forEach(key => {
    const value = builtQuery[key];
    if (value !== undefined) {
      params.append(key, value);
    }
  });

  return params.toString();
};

export const decodeURLSearchRequestQuery = (rawSearchRequestQuery: SearchURLRequestQuery): SearchQuery => {
  const keyword = rawSearchRequestQuery.q || '';

  const categoryId = mapOrUndefined<string, number>(RSearchNumberString, rawSearchRequestQuery.category_id, toNumber);
  const page = mapOrUndefined<string, number>(RSearchNumberString, rawSearchRequestQuery.page, toNumber);
  const order = mapOrUndefined<SearchOrder>(RSearchOrder, rawSearchRequestQuery.order, toAsIs);
  const isAdultExcluded = mapOrUndefined<SearchYesNo, boolean>(
    RSearchYesNo,
    rawSearchRequestQuery.adult_exclude,
    toBoolean,
  );
  const isSerial = mapOrUndefined<SearchYesNo, boolean>(RSearchYesNo, rawSearchRequestQuery.serial, toBoolean);
  const isRental = mapOrUndefined<SearchYesNo, boolean>(RSearchYesNo, rawSearchRequestQuery.rent, toBoolean);
  const isSelect = mapOrUndefined<SearchYesNo, boolean>(RSearchYesNo, rawSearchRequestQuery.select, toBoolean);

  return {
    keyword,
    page,
    categoryId,
    order,
    isAdultExcluded,
    isSerial,
    isRental,
    isSelect,
  };
};

export const ITEM_PER_PAGE = 24;
export const MAX_PAGE = 400;
export const encodeSearchAPIRequestQuery = (searchQuery: SearchQuery): SearchAPIRequestQuery => {
  const start = mapOrUndefined<number, string>(RSearchNonNegativeInteger, searchQuery.page, page =>
    Math.min(ITEM_PER_PAGE * (page - 1), ITEM_PER_PAGE * (MAX_PAGE - 1)).toString(),
  );

  const builtOptions = buildSearchRequestOptions(searchQuery);
  const builtQuery = {
    ...builtOptions,
    keyword: searchQuery.keyword,
    start,
  };

  return builtQuery;
};

const PAGE_LIMIT = 24;
const MAX_SEARCH_KEYWORD_LENGTH = 64;

const getSearchParam = {
  adultOption: (adultExclude: unknown, isInApp: boolean) => {
    switch (adultExclude) {
      case 'y':
        return AdultOption.ExcludeAdult;
      case 'n':
        return AdultOption.None;
      default:
        return isInApp ? AdultOption.AppAll : AdultOption.None;
    }
  },
  categoryId: (categoryId: unknown) => {
    if (typeof categoryId === 'string') {
      const parsedCategoryId = parseInt(categoryId, 10);
      return Number.isNaN(parsedCategoryId) ? null : parsedCategoryId;
    }

    return null;
  },
  order: (order: unknown) => {
    switch (order) {
      case 'price':
        return RiGridSearchOrder.Price;
      case 'recent':
        return RiGridSearchOrder.Recent;
      case 'review_cnt':
        return RiGridSearchOrder.ReviewCnt;
      case 'score':
      default:
        return RiGridSearchOrder.Score;
    }
  },
  page: (page: unknown) => {
    if (typeof page === 'string') {
      const parsedPage = parseInt(page, 10);
      return Number.isNaN(parsedPage) ? 1 : parsedPage;
    }

    return 1;
  },
  keyword: (keyword: unknown) => {
    if (typeof keyword === 'string') {
      return [keyword];
    }

    if (Array.isArray(keyword) && typeof keyword[0] === 'string') {
      return [keyword[0]];
    }

    return [''];
  },
};

export const buildRiGridSearchParams = (query: Record<string, Any>, isInApp: boolean): SearchGridParams => {
  const keyword = getSearchParam.keyword(query.q);
  const isPublisherQuery = keyword[0].startsWith('출판사:');
  const what = isPublisherQuery ? SearchWhat.Publisher : SearchWhat.Base;

  return {
    adultOption: getSearchParam.adultOption(query.adult_exclude, isInApp),
    categoryId: getSearchParam.categoryId(query.category_id),
    keyword: isPublisherQuery ? [keyword[0].replace('출판사:', '')] : keyword,
    order: getSearchParam.order(query.order),
    pageLimitInput: {
      limit: PAGE_LIMIT,
      page: getSearchParam.page(query.page),
    },
    rent: query.rent === 'y',
    what,
  };
};

export const validateSearchQuery = (q: unknown): boolean => {
  if (typeof q === 'string') {
    return q.length <= MAX_SEARCH_KEYWORD_LENGTH;
  }

  if (Array.isArray(q) && typeof q[0] === 'string') {
    return q[0].length <= MAX_SEARCH_KEYWORD_LENGTH;
  }

  return false;
};
