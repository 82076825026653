import { css, SerializedStyles } from '@emotion/react';

import { lineClamp } from '@/components/styles';
import { RIDITheme } from '@/components/styles/themes';

import * as indexStyles from '../index.styles';

export const historyHeaderStyle = css`
  justify-content: space-between;
  ${indexStyles.searchPopupHeaderStyle};
`;

export const historyHeaderTextStyle = (theme: RIDITheme): SerializedStyles => css`
  ${indexStyles.searchPopupHeaderTextStyle(theme)};
`;

export const historyItemsStyle = css`
  padding-bottom: 5px;
`;

export const historyItemStyle = (theme: RIDITheme): SerializedStyles => css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${indexStyles.searchItemStyle(theme)};
`;

export const historyItemFocusedStyle = (theme: RIDITheme): SerializedStyles => css`
  ${indexStyles.searchItemFocusedStyle(theme)};
`;

export const historyItemLinkStyle = css`
  text-align: left;
  padding: 9px 16px;
  flex: 1;
  min-width: 0;
`;

export const historyItemTextStyle = (theme: RIDITheme): SerializedStyles => css`
  ${lineClamp(2, '22px')};
  color: ${theme.colors.fillPrimary};
  letter-spacing: -0.01em;
`;

export const historyItemRemoveStyle = css`
  height: 40px;
  width: 48px;
  padding: 12px 16px;
  outline: none;
  flex: none;
`;

export const historyItemRemoveIconStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.fillSecondary};
  font-size: 16px;
`;

export const historyNoEntryStyle = (theme: RIDITheme): SerializedStyles => css`
  text-align: center;
  padding-top: 40px;
  padding-bottom: 60px;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: ${theme.colors.grey500};
`;

export const historyOptionStyle = (theme: RIDITheme): SerializedStyles => css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${theme.colors.grey200};
  padding: 16px 0;
  margin: 0 16px;
  color: ${theme.colors.fillSecondary};
`;

export const historyOptionButtonStyle = css`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  outline: none;
`;
