import Link, { LinkProps } from 'next/link';
import { cloneElement, HTMLProps, MouseEvent, MouseEventHandler, PropsWithChildren, ReactElement } from 'react';

import { EventParamsType, sendClickEvent } from '@/utils/eventClient';

import { TrackEventProps } from './types';

const injectEventClientInHTMLElement = (
  event: MouseEvent<HTMLElement>,
  screenName: string,
  target: string,
  params: EventParamsType,
  onClick?: MouseEventHandler<HTMLElement>,
) => {
  sendClickEvent(screenName, target, params);

  if (onClick) {
    onClick(event);
  }
};

const renderWithHTMLElement = (
  children: ReactElement,
  screenName: string,
  target: string,
  params: EventParamsType,
): ReactElement | null => {
  const childrenProps = children.props as HTMLProps<HTMLElement>;
  const injectedOnClick = (event: MouseEvent<HTMLElement>) =>
    injectEventClientInHTMLElement(event, screenName, target, params, childrenProps.onClick);

  return cloneElement(children, {
    onClick: injectedOnClick,
  });
};

const renderWithNextLink = (
  children: ReactElement,
  screenName: string,
  target: string,
  params: EventParamsType,
): ReactElement | null => {
  const nextLink = children;
  const nextLinkProps = nextLink.props as PropsWithChildren<LinkProps>;

  const nextLinkChild = nextLinkProps.children as ReactElement;
  const nextLinkChildProps = nextLinkChild.props as HTMLProps<HTMLElement>;

  const injectedOnClick = (event: MouseEvent<HTMLElement>) => {
    sendClickEvent(screenName, target, params);
    nextLinkChildProps.onClick?.(event);
  };

  return cloneElement(
    nextLink,
    undefined,
    cloneElement(nextLinkChild, {
      onClick: injectedOnClick,
    }),
  );
};

export const TrackClickEvent = ({ children, screenName, target, params }: TrackEventProps): ReactElement | null => {
  if (children.type === Link) {
    return renderWithNextLink(children, screenName, target, params || {});
  }
  return renderWithHTMLElement(children, screenName, target, params || {});
};
