import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const searchItemStyle = (theme: RIDITheme): SerializedStyles => css`
  outline: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;

  &,
  button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
  }

  &:active,
  &:hover,
  &:focus-within {
    background-color: ${theme.colors.grey100};
  }

  ${orBelow(
    BreakPoint.Large,
    css`
      &:active,
      &:hover,
      &:focus-within {
        background-color: transparent;
      }
    `,
  )};
`;

export const searchItemFocusedStyle = (theme: RIDITheme): SerializedStyles => css`
  background-color: ${theme.colors.grey100};
`;

export const searchPopupHeaderStyle = css`
  display: flex;
  padding: 0 16px;
  padding-top: 14px;
  padding-bottom: 6px;
`;

export const searchPopupHeaderTextStyle = (theme: RIDITheme): SerializedStyles => css`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: ${theme.colors.fillSecondary};
`;
