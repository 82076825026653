import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IconOutLink } from '@/assets/svgs/system';
import {
  currentNavigationRouteSelector,
  globalNavigationSelector,
  updateVisitedNavigationIdsAction,
  visitedNavigationIdsSelector,
} from '@/features/global/globalNavigationBar/navigation/navigationSlice';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { Navigation } from '@/models/backendsApi/v2/Navigation/NavigationType';
import { getParsedCookie } from '@/utils/cookie';
import { findLeafNavigation } from '@/utils/navigation';

import { useOnScrollRightEnd } from '../../../hooks/useOnScrollRightEnd';
import { usePageContext } from '../PageContext';
import * as styles from './GlobalNavigationBarBottom.styles';
import { GlobalNavigationBarLink } from './GlobalNavigationBarLink';

const emptyNavigation: Navigation = {
  id: -1,
  title: '',
  path: '',
  image_url: null,
  resource_url: null,
  is_default: false,
  view_id: null,
  children: [],
};

type Props = {
  onScrollRightEnd: (isAtRightEnd: boolean) => void;
  isInExperimentPage: boolean;
};

export const GlobalNavigationBarBottom = ({ onScrollRightEnd, isInExperimentPage }: Props): ReactJSX.Element => {
  const dispatch = useAppDispatch();

  const pageContext = usePageContext();
  const globalNavigation = useSelector(globalNavigationSelector);
  const currentNavigationRoute = useSelector(currentNavigationRouteSelector);
  const visitedNavigationIds = useSelector(visitedNavigationIdsSelector);

  useEffect(() => {
    if (!visitedNavigationIds) {
      dispatch(updateVisitedNavigationIdsAction(getParsedCookie(document.cookie)?.ridi_nav));
    }
  }, [dispatch, visitedNavigationIds]);

  const navigationItems = useMemo(
    () =>
      globalNavigation
        ?.map(item => ({
          ...item,
          isSelected: currentNavigationRoute?.some(route => route.id === item.id),
          openInNewTab: false,
          leafNavigation: findLeafNavigation(item, visitedNavigationIds || new Set()),
          icon: <></>,
        }))
        .concat({
          ...emptyNavigation,
          title: '셀렉트',
          isSelected: false,
          openInNewTab: true,
          leafNavigation: {
            ...emptyNavigation,
            path: 'https://select.ridibooks.com',
          },
          icon: <IconOutLink />,
        }),
    [globalNavigation, currentNavigationRoute, visitedNavigationIds],
  );

  const scrollableRef = useOnScrollRightEnd({ onScrollRightEnd });

  return (
    <nav
      css={[styles.topItemsStyle, isInExperimentPage ? styles.linkGenreHomeStyle : styles.linkNotGenreHomeStyle]}
      ref={scrollableRef}>
      {navigationItems?.map(item => (
        <GlobalNavigationBarLink
          key={item.id}
          css={[
            styles.topItemLinkStyle,
            item.isSelected && styles.topItemSelectedLinkStyle,
            !visitedNavigationIds && styles.disableClickWhileHydrationStyle,
          ]}
          trackingParams={{
            screenName: pageContext.screenName,
            target: 'navigation_top',
            params: {
              ...pageContext.params,
              navigation_title: item.title,
              navigation_path: item.leafNavigation.path,
            },
          }}
          openInNewTab={item.openInNewTab}
          href={item.leafNavigation.path}>
          {item.title}
          {item.icon}
        </GlobalNavigationBarLink>
      ))}
    </nav>
  );
};
