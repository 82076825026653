import styled from '@emotion/styled';
import { ChangeEvent, DetailedHTMLProps, InputHTMLAttributes, ReactNode } from 'react';

import * as styles from './Switch.styles';

const StyledSwitchContainer = styled.span<{ checked?: boolean }>`
  ${({ theme }) => styles.containerStyle(theme)};
  ${({ checked, theme }) => checked && styles.containerCheckedStyle(theme)};
`;

const StyledSwitchInput = styled.input`
  //Specificity hack
  ${StyledSwitchContainer} > input& {
    ${styles.inputStyle};
  }
`;

const StyledSwitchIconContainer = styled.span<{ checked?: boolean }>`
  ${({ checked, theme }) => styles.iconContainerStyle(theme, checked)};
`;

const StyledSwitchIconMarginPlaceholder = styled.span<{ checked?: boolean }>`
  ${({ checked }) => styles.iconMarginPlaceholderStyle(checked)};
`;

type HTMLInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export interface SwitchProps extends Omit<HTMLInputProps, 'type' | 'onChange'> {
  eventScreenName?: string;
  eventTarget?: string;
  eventParams?: Record<string, string | number>;
  onChange?(checked: boolean): void;
  className?: string;
  switchIcon?: ReactNode;
}

export const Switch = ({ className, onChange, checked, switchIcon, ...props }: SwitchProps): ReactJSX.Element => {
  const onInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked);
  };

  const switchComponent = (
    <StyledSwitchContainer className={className} checked={checked} data-testid="switch-container">
      <StyledSwitchIconMarginPlaceholder checked={checked} />
      <StyledSwitchIconContainer checked={checked}>{switchIcon}</StyledSwitchIconContainer>
      <StyledSwitchInput {...props} checked={checked} onChange={onInputChanged} type="checkbox" />
    </StyledSwitchContainer>
  );

  return switchComponent;
};
