import { AuthorRole } from '@/base/interfaces/Author';
import { sortAuthors } from '@/utils/author';

export const getAuthorLabel = (authors: { name: string; role: AuthorRole }[]): string | null => {
  const authorNames = sortAuthors(authors.filter(({ role }) => role !== 'translator')).map(({ name }) => name);
  if (authorNames.length === 0) {
    return null;
  }

  if (authorNames.length > 2) {
    return `${authorNames[0]} 외 ${authorNames.length - 1}명`;
  }

  return authorNames.join(', ');
};
