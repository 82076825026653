import { css, SerializedStyles } from '@emotion/react';

import { RIDITheme } from '@/components/styles/themes';

export const containerStyle = (theme: RIDITheme): SerializedStyles => css`
  display: flex;
  width: 50px;
  height: 30px;
  border: 2px solid ${theme.colors.grey400};
  font-size: 26px;

  border-radius: 15px;
  cursor: pointer;

  transition: 0.2s;
  background: ${theme.colors.grey400};
  position: relative;
`;

export const containerCheckedStyle = (theme: RIDITheme): SerializedStyles => css`
  background: ${theme.colors.blue};
  border-color: ${theme.colors.blue};
`;

export const inputStyle = css`
  display: inline-flex;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export const iconMarginPlaceholderStyle = (checked?: boolean): SerializedStyles => css`
  flex-grow: ${checked ? 1 : 0};
  flex-shrink: 1;
  flex-basis: 0;

  transition: 0.2s;
`;

export const iconContainerStyle = (theme: RIDITheme, checked?: boolean): SerializedStyles => css`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 1em;
  height: 1em;

  border: none;
  border-radius: 0.5em;
  background: white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  pointer-events: none;

  transition: 0.2s;

  color: ${checked ? theme.colors.blue : theme.colors.grey400};
`;
