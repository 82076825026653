import { useState } from 'react';

import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

export const useResponsiveIsBelow = (breakpoint: number, initialState = false): boolean => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(initialState);

  useIsomorphicLayoutEffect(() => {
    const onResizeHandler = () => {
      setCurrentBreakpoint(window.innerWidth <= breakpoint);
    };

    window.addEventListener('resize', onResizeHandler);
    onResizeHandler();

    return () => {
      window.removeEventListener('resize', onResizeHandler);
    };
  }, [breakpoint]);

  return currentBreakpoint;
};
