import {
  Array as RArray,
  Literal as RLiteral,
  Partial as RPartial,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

export const RSearchYesNo = RUnion(RLiteral('y'), RLiteral('n'));
export type SearchYesNo = RStatic<typeof RSearchYesNo>;

export const RSearchNumberString = RString.withConstraint(str => /^\d+$/.test(str));
export type SearchNumberString = RStatic<typeof RSearchNumberString>;

export const RSearchOrder = RUnion(RLiteral('score'), RLiteral('recent'), RLiteral('review_cnt'), RLiteral('price'));
export type SearchOrder = RStatic<typeof RSearchOrder>;

export const RSearchWhere = RUnion(RLiteral('book'), RLiteral('author'));
export type SearchWhere = RStatic<typeof RSearchWhere>;

export const RSearchWhat = RUnion(RLiteral('base'), RLiteral('publisher'), RLiteral('instant'));
export type SearchWhat = RStatic<typeof RSearchWhat>;

export const RSearchRequestOptions = RRecord({
  category_id: RSearchNumberString.optional(),
  order: RSearchOrder.optional(),
  adult_exclude: RSearchYesNo.optional(),
  serial: RSearchYesNo.optional(),
  rent: RSearchYesNo.optional(),
  select: RSearchYesNo.optional(),
  adult: RSearchYesNo.optional(),
});
export type SearchRequestOptions = RStatic<typeof RSearchRequestOptions>;

export const RSearchRequestQuery = RRecord({
  keyword: RString,
  start: RSearchNumberString.optional(),
}).And(RSearchRequestOptions);
export type SearchRequestQuery = RStatic<typeof RSearchRequestQuery>;

export const RSearchRequest = RRecord({
  query: RSearchRequestQuery.And(
    RPartial({
      what: RSearchWhat,
      where: RArray(RSearchWhere),
    }),
  ),
});
export type SearchRequest = RStatic<typeof RSearchRequest>;
