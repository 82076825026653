import { css, SerializedStyles } from '@emotion/react';

import { lineClamp } from '@/components/styles';
import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

import * as indexStyles from '../index.styles';

export const adultExcludeStyle = (theme: RIDITheme): SerializedStyles => css`
  ${indexStyles.searchItemStyle(theme)};

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 16px;
  padding-bottom: 15px;

  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: ${theme.colors.fillSecondary};

  &:active,
  &:hover,
  &:focus-within {
    background-color: transparent;
  }
`;

export const resultHeaderStyle = (theme: RIDITheme): SerializedStyles => css`
  ${indexStyles.searchPopupHeaderStyle};
  ${indexStyles.searchPopupHeaderTextStyle(theme)};
`;

export const resultWrapperStyle = css`
  list-style-type: none;
`;

export const resultItemStyle = (theme: RIDITheme): SerializedStyles => css`
  ${indexStyles.searchItemStyle(theme)};
  ${orBelow(
    BreakPoint.Large,
    css`
      min-height: 40px;
    `,
  )};
`;

export const resultItemFocusedStyle = (theme: RIDITheme): SerializedStyles => css`
  ${indexStyles.searchItemFocusedStyle(theme)};
`;

export const resultItemButtonStyle = (theme: RIDITheme): SerializedStyles => css`
  display: flex;
  align-items: center;
  color: ${theme.colors.fillPrimary};
  height: 100%;
  width: 100%;
  padding: 9px 16px;
  text-align: left;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  &:link,
  &:visited {
    // FIXME overriding books-backend's page_base_renewal.css when /partial
    color: ${theme.colors.fillPrimary};
  }

  b,
  strong {
    color: ${theme.colors.blue};
    font-weight: inherit;
  }
`;

export const resultItemDescriptionStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.fillSecondary};
  margin-left: 10px;
  padding-left: 1px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.01em;
`;

export const bookResultItemButtonStyle = css`
  flex-wrap: wrap;
  padding: 9px 11px;
  padding-bottom: 6px;
`;

export const bookResultItemDescriptionStyle = css`
  display: inline-flex;
  align-items: center;
  margin: 0 5px;
  margin-bottom: 3px;
  min-width: 0;
`;

export const bookResultItemDescriptionContentStyle = css`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const bookTitleStyle = css`
  margin: 0 5px;
  margin-bottom: 3px;
  ${lineClamp(2, '22px')};
`;

export const bookAuthorPublisherStyle = css`
  font-size: 13px;
  line-height: 16px;
`;

export const bookVerticalDividerStyle = css`
  display: inline-block;
  background: currentColor;
  width: 2px;
  height: 2px;
  border-radius: 2px;
  transform: scale(0.75);

  line-height: 16px;
  margin: 7px 4px;
  vertical-align: text-top;
`;

export const adultBadgeWrapperStyle = css`
  flex: none;
  display: inline-flex;
  margin-left: 4px;
`;

export const adultBadgeStyle = css`
  font-size: 15px;

  /** dark mode에서 색상 반전 이슈 처리 */
  color-scheme: only light;
`;

export const dividerStyle = (theme: RIDITheme): SerializedStyles => css`
  display: block;
  height: 1px;
  border: none;
  background: ${theme.colors.grey200};
  margin: 0 16px;
  margin-top: 4px;
`;

export const authorAuthorStyle = css`
  flex: none;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const authorBookStyle = css`
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
`;
