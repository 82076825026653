import { useRouter } from 'next/router';
import { DependencyList, useEffect, useMemo } from 'react';

import { throttle } from '@/utils/functions';

export const useRouteFetchEffect = (routeFetchFn: () => Promise<void> | void, deps: DependencyList = []): void => {
  const router = useRouter();
  const throttledFetch = useMemo(() => throttle(routeFetchFn), [routeFetchFn]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  useEffect(throttledFetch, [...deps, router.asPath, throttledFetch]);
};
