import Link from 'next/link';
import { forwardRef, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { shouldUseHistoryNavigationSelector } from '@/features/global/globalNavigationBar';
import { EventParamsType } from '@/utils/eventClient';

import { TrackClickEvent } from '../EventClient/TrackClickEvent';

type GlobalNavigationBarLinkProps = {
  className?: string;
  children: ReactNode;
  href: string;
  openInNewTab?: boolean;
  trackingParams: {
    screenName: string;
    target: string;
    params?: EventParamsType;
  };
};

export const GlobalNavigationBarLink = forwardRef<HTMLAnchorElement, GlobalNavigationBarLinkProps>(
  ({ className, children, trackingParams, openInNewTab, href }, ref): ReactJSX.Element => {
    const shouldUseHistoryNavigation = useSelector(shouldUseHistoryNavigationSelector);
    const anchor = (
      <TrackClickEvent
        screenName={trackingParams.screenName}
        target={trackingParams.target}
        params={trackingParams.params}>
        <a className={className} href={href} ref={ref} target={openInNewTab ? '_blank' : undefined} rel="noreferrer">
          {children}
        </a>
      </TrackClickEvent>
    );

    if (!shouldUseHistoryNavigation) {
      return anchor;
    }

    return (
      <TrackClickEvent
        screenName={trackingParams.screenName}
        target={trackingParams.target}
        params={trackingParams.params}>
        <Link legacyBehavior href={href} passHref>
          {anchor}
        </Link>
      </TrackClickEvent>
    );
  },
);
