import styled from '@emotion/styled';

import { IconDelete } from '@/assets/svgs/system';
import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { usePageContext } from '@/components/common/PageContext';

import { SearchHistory as SearchHistoryType } from '../hooks/useSearchHistory';
import * as styles from './SearchHistory.styles';

const StyledHistoryItem = styled.li<{ focused?: boolean }>`
  ${({ theme }) => styles.historyItemStyle(theme)};
  ${({ focused, theme }) => focused && styles.historyItemFocusedStyle(theme)};
`;

interface SearchHistoryProps {
  className?: string;
  history: SearchHistoryType;
  goToSearchResult: (keyword: string) => void;
  focusedIndex: number | null;
  setFocusedIndex: (focusedIndex: number | null) => void;
}

export const SearchHistory = ({
  className,
  history,
  goToSearchResult,
  focusedIndex,
  setFocusedIndex,
}: SearchHistoryProps): ReactJSX.Element => {
  const hasHistory = history.history.length > 0;
  const pageContext = usePageContext();

  return (
    <div className={className}>
      <div css={styles.historyHeaderStyle}>
        <span css={styles.historyHeaderTextStyle}>최근 검색어</span>

        {hasHistory && history.historyEnabled && (
          <TrackClickEvent
            screenName={pageContext.screenName}
            target="search_reset_recent_item"
            params={pageContext.params}>
            <button css={styles.historyHeaderTextStyle} type="button" onClick={() => history.clearHistory()}>
              전체삭제
            </button>
          </TrackClickEvent>
        )}
      </div>

      {history.historyEnabled && hasHistory ? (
        <ul css={styles.historyItemsStyle}>
          {history.history.map((historyItem: string, index: number) => (
            <StyledHistoryItem
              key={historyItem}
              focused={index === focusedIndex}
              onMouseEnter={() => setFocusedIndex(index)}
              onMouseLeave={() => setFocusedIndex(null)}
              data-testid="search-history-item">
              <TrackClickEvent
                screenName={pageContext.screenName}
                target="search_recent_item"
                params={{ ...pageContext.params, keyword: historyItem }}>
                <button css={styles.historyItemLinkStyle} type="button" onClick={() => goToSearchResult(historyItem)}>
                  <span css={styles.historyItemTextStyle}>{historyItem}</span>
                </button>
              </TrackClickEvent>
              <TrackClickEvent
                screenName={pageContext.screenName}
                target="search_delete_recent_item"
                params={{ ...pageContext.params, keyword: historyItem }}>
                <button
                  css={styles.historyItemRemoveStyle}
                  type="button"
                  onClick={() => history.removeHistory(historyItem)}
                  aria-label={`검색어 "${historyItem}" 삭제`}>
                  <IconDelete css={styles.historyItemRemoveIconStyle} />
                </button>
              </TrackClickEvent>
            </StyledHistoryItem>
          ))}
        </ul>
      ) : (
        <div css={styles.historyNoEntryStyle}>
          {history.historyEnabled ? '최근 검색어 내역이 없습니다.' : '검색어 저장기능이 꺼져있습니다.'}
        </div>
      )}

      <div css={styles.historyOptionStyle}>
        <TrackClickEvent
          screenName={pageContext.screenName}
          target="search_toggle_recent_history"
          params={{ ...pageContext.params, new_recent_history: !history.historyEnabled }}>
          <button
            css={styles.historyOptionButtonStyle}
            type="button"
            onClick={() => history.setHistoryEnabled(!history.historyEnabled)}>
            {history.historyEnabled ? '검색어 저장 끄기' : '검색어 저장 켜기'}
          </button>
        </TrackClickEvent>
      </div>
    </div>
  );
};
