import { debug } from '@/utils/debug';

const logger = debug('RGBA');

/**
 * 올바른 16진법 색상 표기법임을 확인한다
 *
 * @example
 * isValidHexColorString('#111') // returns true;
 * isValidHexColorString('#123456') // returns true;
 * isValidHexColorString('111') // returns false;
 * isValidHexColorString('#123') // returns false;
 *
 * @param hexColor
 */
const isValidHexColorString = (hexColor: string): boolean =>
  /^#[0-9a-fA-F]{3}$/.test(hexColor) || /^#[0-9a-fA-F]{6}$/.test(hexColor);

/**
 * 6비트로 표현된 16진법 칼라값을 10진법 숫자 배열로 변환한다
 *
 * @example
 * rgbArray('#000000') // returns [0, 0, 0];
 * rgbArray('#FFFFFF') // returns [255, 255, 255];
 * rgbArray('wrong') // returns [0, 0, 0];
 *
 * @param hex
 */
const rgbArray = (hexColor: string): number[] => {
  if (!isValidHexColorString(hexColor)) {
    logger(`유효하지 않은 색상 표기법입니다: ${hexColor}`);
    return [0, 0, 0];
  }

  let hex = hexColor.slice(1);

  if (hex.length === 3) {
    const splittedHex = hex.split('');
    hex = splittedHex.reduce((result, e) => result + e + e, '');
  }

  const redHex = hex.slice(0, 2);
  const greenHex = hex.slice(2, 4);
  const blueHex = hex.slice(4);
  const red = parseInt(redHex, 16);
  const green = parseInt(greenHex, 16);
  const blue = parseInt(blueHex, 16);

  return [red, green, blue];
};

/**
 * 16진법의 칼라값을 rgb 표기법으로 변경한다
 *
 * @example
 * rgb('#000000') // returns 'rgb(255, 255, 255)'
 * rgb('#000') // returns 'rgb(255, 255, 255)'
 * rgb('wrong') // returns 'rgb(0, 0, 0);
 *
 * @param hexColor
 */
const rgb = (hexColor: string): string => {
  const [red, green, blue] = rgbArray(hexColor);

  return `rgb(${red}, ${green}, ${blue})`;
};

/**
 * 16진법의 칼라값을 alpha를 적용한 rgba 표기법으로 변경한다
 *
 * @example
 * rgba('#000000', 0.1) // returns 'rgba(255, 255, 255, 0.1)'
 * rgba('#000') // returns 'rgba(255, 255, 255, 1)'
 * rgba('wrong') // returns 'rgba(0, 0, 0, 1);
 *
 * @param hexColor
 * @param alpha 0 이상 1 이하의 값
 */
const rgba = (hexColor: string, alpha = 1): string => {
  const [red, green, blue] = rgbArray(hexColor);

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

export { rgb, rgba };
